(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/form/views/checkbox-group_partial.js') >= 0) return;  svs.modules.push('/components/ui/form/views/checkbox-group_partial.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.ui=_svs.ui||{};
_svs.ui.form=_svs.ui.form||{};
_svs.ui.form.partials=_svs.ui.form.partials||{};
svs.ui.form.partials.checkbox_group = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " form-toggle-inline";
},"3":function(container,depth0,helpers,partials,data) {
    return " form-toggle-thin";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " style=\"min-width: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"min-width") || (depth0 != null ? lookupProperty(depth0,"min-width") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"min-width","hash":{},"data":data,"loc":{"start":{"line":1,"column":142},"end":{"line":1,"column":155}}}) : helper)))
    + "\"";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"items") || (depth0 != null ? lookupProperty(depth0,"items") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"items","hash":{},"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":23}}}) : helper)));
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"content","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":42}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-toggle-group"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"inline") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":29},"end":{"line":1,"column":69}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"thin") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":69},"end":{"line":1,"column":105}}})) != null ? stack1 : "")
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"min-width") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":106},"end":{"line":1,"column":163}}})) != null ? stack1 : "")
    + ">\n	"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":2,"column":49}}})) != null ? stack1 : "")
    + "\n</div>";
},"useData":true});
Handlebars.partials['ui-form-checkbox_group'] = svs.ui.form.partials.checkbox_group;
})(svs, Handlebars);


 })(window);