(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/form/assets/javascripts/handlebars-helpers.js') >= 0) return;  svs.modules.push('/components/ui/form/assets/javascripts/handlebars-helpers.js');

'use strict';

function registerHelpers(hbs) {
  hbs.registerHelper('defaultVar', function(inputVar, defaultVar) {
    return inputVar === undefined ? defaultVar : inputVar;
  });
}

if (svs.isServer) {
  registerHelpers(require('hbs'));
} else {
  registerHelpers(Handlebars);
}



 })(window);