(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/form/assets/javascripts/forms.js') >= 0) return;  svs.modules.push('/components/ui/form/assets/javascripts/forms.js');

'use strict';

svs.ui = svs.ui || {};
svs.ui.forms = svs.ui.forms || {};
svs.ui.Forms = class Forms {
  constructor(options) {
    this.initForm(options.el);
  }
  initForm(el) {
    const formEl = document.querySelector(".".concat(el));
    const inputs = formEl.querySelectorAll('input, textarea');
    const selects = formEl.querySelectorAll('select');
    for (let i = 0; i < inputs.length; i++) {
      if (!svs.core.detect.browser.msie() && !svs.core.detect.browser.ie()) {
        const desc = "".concat(inputs[i].id, "_desc");
        inputs[i].closest('.control-group') && inputs[i].closest('.control-group').querySelector('.info-message') && inputs[i].closest('.control-group').querySelector('.info-message').setAttribute('id', desc);
      }
      if (inputs[i].value && inputs[i].classList.contains('js-input')) {
        inputs[i].classList.add('input-valid');
        formEl.querySelector(".js-label-".concat(inputs[i].id)).classList.remove('hide-label');
      }
      inputs[i].oninput = e => {
        if (e.currentTarget.classList.contains('js-input') && !svs.core.detect.browser.msie() && !svs.core.detect.browser.ie() && e.target.closest('.control-group').classList.contains('error')) {
          e.target.closest('.control-group').classList.remove('error');
        }
        if (e.target.value || e.target.validity.badInput) {
          e.target.classList.add('input-valid');
        } else {
          e.target.classList.remove('input-valid');
        }
      };

      if (inputs[i].type === 'password') {
        const input = inputs[i];
        const sufxEl = input.nextElementSibling.querySelector('.input-sufx');
        sufxEl.style.cursor = 'pointer';
        input.style.width = "".concat(input.clientWidth - input.nextElementSibling.querySelector('.input-trailing').clientWidth, "px");
        sufxEl.addEventListener('click', e => {
          e.preventDefault();
          e.stopImmediatePropagation();
          e.stopPropagation();
          const openEye = e.target.offsetParent.querySelector('.icon-eye');
          const closedEye = e.target.offsetParent.querySelector('.icon-eye-closed');
          if (closedEye) {
            closedEye.classList.add('icon-eye');
            closedEye.classList.remove('icon-eye-closed');
            input.type = 'text';
          } else if (openEye) {
            openEye.classList.add('icon-eye-closed');
            openEye.classList.remove('icon-eye');
            input.type = 'password';
          }
          e.target.closest('.control-group').querySelector('.js-input').focus();
        });
      }
      if (inputs[i].nextElementSibling.querySelector('.input-sufx').innerHTML.length) {
        const input = inputs[i];
        input.style.width = input.clientWidth > 0 ? "".concat(input.clientWidth - input.nextElementSibling.querySelector('.input-trailing').clientWidth, "px") : "calc(100% - 40px)";
      }
      if (inputs[i].nextElementSibling.querySelector('.input-prefx').innerHTML.length) {
        const input = inputs[i];
        const inputLabel = inputs[i].nextElementSibling.querySelector('.original-label');
        inputLabel.style.textIndent = "".concat(!input.value.length ? input.nextElementSibling.querySelector('.input-prefx').clientWidth : 0, "px");
        input.style.paddingLeft = "".concat(input.nextElementSibling.querySelector('.input-prefx').clientWidth + 18, "px");
      }
    }
    for (let i = 0; i < selects.length; i++) {
      selects[i].onchange = e => {
        if (e.target.classList.contains('select-v2') && !svs.core.detect.browser.msie() && !svs.core.detect.browser.ie() && e.target.closest('.control-group').classList.contains('error')) {
          e.target.closest('.control-group').classList.remove('error');
        }
      };
    }
  }
};

 })(window);