(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/form/views/branding_partial.js') >= 0) return;  svs.modules.push('/components/ui/form/views/branding_partial.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.ui=_svs.ui||{};
_svs.ui.form=_svs.ui.form||{};
_svs.ui.form.partials=_svs.ui.form.partials||{};
svs.ui.form.partials.branding = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row "
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"bgColor") : depths[1]), depth0))
    + "\">\n  {{ui 'form/checkbox' branding=[] checked=true value=123 inline=true}}\n  ---\n  {{ui 'form/checkbox' branding=[] disabled=true value=123 inline=true}}\n  ---\n  {{ui 'form/checkbox' branding=[] checked=true disabled=true value=123 inline=true}}\n  ---\n  {{ui 'form/checkbox' branding=[] value=123 label='Label as variable' inline=true}}\n  ---\n</div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row "
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"bgColor") : depths[1]), depth0))
    + "\">\n---\n{{ui 'form/radio' branding=[] checked=true value=123 name='toggle-radio-pill[]' inline=true}}\n---\n{{ui 'form/radio' branding=[] disabled=true value=123 name='toggle-radio-pill[]' inline=true}}\n---\n{{ui 'form/radio' branding=[] value=123 label='Radio label example' name='toggle-radio-pill[]' inline=true}}\n---\n</div>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row "
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"bgColor") : depths[1]), depth0))
    + "\">\n  <div class=\"col-xs-12 col-sm-6 margin-xs-bottom-1\">\n    {{#ui 'form/checkbox-group'}}\n      {{#ui 'form/checkbox' checked=true branding=[] value=123}}Button label{{/ui}}\n      {{#ui 'form/checkbox' branding=[] value=123}}Button label{{/ui}}\n    {{/ui}}\n  </div>\n  <div class=\"col-xs-12 col-sm-6 xs-margin-top padding-xs-left\">\n    {{#ui 'form/checkbox-group'}}\n      {{#ui 'form/checkbox' branding=[] value=123}}Button label{{/ui}}\n    {{/ui}}\n  </div>\n</div>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row "
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"bgColor") : depths[1]), depth0))
    + "\">\n  <div class=\"col-xs-12 col-sm-6\">\n    {{#ui 'form/radio-group'}}\n      {{#ui 'form/radio-button' checked=true branding=[] min-width='140px' value=123}}Button label{{/ui}}\n      {{#ui 'form/radio-button' branding=[] min-width='140px' value=123}}Button label{{/ui}}\n      {{#ui 'form/radio-button' branding=[] min-width='140px' value=123}}Button label{{/ui}}\n    {{/ui}}\n  </div>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<h3>Toggle Checkbox</h3>\n"
    + ((stack1 = (lookupProperty(helpers,"componentDocs")||(depth0 && lookupProperty(depth0,"componentDocs"))||alias2).call(alias1,depth0,{"name":"componentDocs","hash":{"showHtml":"false","showHbs":"false","id":"toggleCheckbox"},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":18,"column":18}}})) != null ? stack1 : "")
    + "\n<h3>Toggle Radio</h3>\n"
    + ((stack1 = (lookupProperty(helpers,"componentDocs")||(depth0 && lookupProperty(depth0,"componentDocs"))||alias2).call(alias1,depth0,{"name":"componentDocs","hash":{"showHtml":"false","showHbs":"false","id":"toggleRadio"},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":0},"end":{"line":31,"column":18}}})) != null ? stack1 : "")
    + "\n\n<h3>Toggle Checkbox Group</h3>\n"
    + ((stack1 = (lookupProperty(helpers,"componentDocs")||(depth0 && lookupProperty(depth0,"componentDocs"))||alias2).call(alias1,depth0,{"name":"componentDocs","hash":{"showHtml":"false","showHbs":"false","id":"toggleCheckboxGroup"},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":0},"end":{"line":49,"column":18}}})) != null ? stack1 : "")
    + "\n<h3>Toggle Radio Button Group</h3>\n"
    + ((stack1 = (lookupProperty(helpers,"componentDocs")||(depth0 && lookupProperty(depth0,"componentDocs"))||alias2).call(alias1,depth0,{"name":"componentDocs","hash":{"showHtml":"false","showHbs":"false","id":"toggleRadioGroup"},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":0},"end":{"line":62,"column":18}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});
Handlebars.partials['ui-form-branding'] = svs.ui.form.partials.branding;
})(svs, Handlebars);


 })(window);